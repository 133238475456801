<template>
  <b-card>
    <!-- {{items.summary_type}} -->
    <VulnerabilitySummary
      v-if="items.summary_type == 'vulnerability'"
      :items="items"
      :vulnItems="vulnItems"
      :totalRows="totalRows"
    />
    <BaselineSummary v-if="items.summary_type == 'baseline'" :items="items" />
    <IpReputation v-if="items.summary_type == 'ipscore'" :items="items" />
    <SSLLabSummary v-if="items.summary_type == 'ssllabs'" :items="items" />
    <AttackSurfaceSummary
      v-if="items.summary_type == 'Attack surface'"
      :items="items"
    />
    <Dsscan v-if="items.summary_type == 'dsscan'" :items="items" />
  </b-card>
</template>
<script>
import { BCard } from "bootstrap-vue";
import VulnerabilitySummary from "./vulnerabilitySummary.vue";
import BaselineSummary from "./baseLineSummary.vue";
import IpReputation from "./ipReputationSummary.vue";
import SSLLabSummary from "./sslSummary.vue";
import AttackSurfaceSummary from "./attackSurfaceSummary.vue";
import Dsscan from "./dsscanSummary.vue";
export default {
  components: {
    BCard,
    VulnerabilitySummary,
    BaselineSummary,
    IpReputation,
    SSLLabSummary,
    AttackSurfaceSummary,
    Dsscan,
  },
  data() {
    return {
      items: [],
      vulnItems: [],
      ports: [],
    };
  },
  created: function () {
    if (this.$route.query.groupScan === true) {
      this.getGroupScan();
    } else {
      this.getSummary();
    }
  },
  mounted() {
    this.onVerticalCollapse();
  },
  methods: {
    onVerticalCollapse() {
      let coll = true;
      this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", coll);
    },
    getSummary: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "scan/scan/" +
          this.$route.params.id +
          "/scan-summary",
      };
      this.$http(options).then((res) => {
        console.log(res.data, "get summary");
        this.items = res.data;
        this.vulnItems = JSON.parse(res.data.vulns);
        this.totalRows = JSON.parse(res.data.vulns).length;
        // res.data.current_scan_details.ports.tcp.map(function (value, key) {
        //   let port = {
        //     key: res.data.current_scan_details.ports.tcp[key],
        //     value: res.data.current_scan_details.ports.tcp[value],
        //   };

        //   self.ports.push(port);
        // });
      });
    },
    getGroupScan: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "scan/group-scan-summary?scan_id=" +
          this.$route.params.id,
      };
      this.$http(options).then((res) => {
        console.log(res.data, "get summary");
        this.items = res.data;
        this.vulnItems = JSON.parse(res.data.vulns);
        this.totalRows = JSON.parse(res.data.vulns).length;
        // res.data.current_scan_details.ports.tcp.map(function (value, key) {
        //   let port = {
        //     key: res.data.current_scan_details.ports.tcp[key],
        //     value: res.data.current_scan_details.ports.tcp[value],
        //   };

        //   self.ports.push(port);
        // });
      });
    },
  },
};
</script>
