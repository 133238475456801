<template>
  <div>
    <div class="d-flex justify-content-end" v-if="!asmSummary">
      <b-button @click="goBack()" size="sm" variant="primary">Back</b-button>
    </div>
    <div class="row align-items-center">
      <b-col class="col-4" v-if="!asmSummary">
        <h6>
          Scan Name: <span>{{ items.scan_type }}</span>
        </h6>
        <h6>
          Scan Start: <span>{{ items.created_at | formatdate }}</span>
        </h6>
        <h6>
          Scan End: <span>{{ items.last_run_at | formatdate }}</span>
        </h6>
        <h6>
          Asset: <span>{{ items.asset }}</span>
        </h6>
      </b-col>
      <b-col class="col-12">
        <div class="row" v-if="items.current_scan_details">
          <div class="col-12 mt-1" v-if="!asmSummary">
            <h4>Current Scan Details</h4>
          </div>
          <div class="col-12">
            <b-card-code class="border-primary">
              <div>
                <p>
                  <b>Fraud Score</b>:
                  <span style="color: #7367f0">{{
                    items.current_scan_details.fraud_score
                  }}</span>
                </p>
                <p class="" v-if="items.current_scan_details.fraud_score >= 75">
                  Advice : <b-badge variant="warning">suspicious</b-badge> -
                  previous reputation issues or low risk proxy/VPN.
                </p>
                <p
                  class=""
                  v-else-if="items.current_scan_details.fraud_score >= 88"
                >
                  Advice :<b-badge variant="danger">high risk</b-badge> - recent
                  abusive behavior over the past 24-48 hours.
                </p>
                <p
                  class=""
                  v-else-if="items.current_scan_details.fraud_score >= 90"
                >
                  Advice :<b-badge variant="danger">high risk</b-badge> - recent
                  reputation issues like abuse associated with a name, email,
                  phone number, payment method, etc.
                </p>
              </div>
              <div>
                <p>
                  <b>Abuse Velocity</b>:
                  <span style="color: #7367f0">{{
                    items.current_scan_details.abuse_velocity
                  }}</span>
                </p>
                <!-- <p v-if="ipr.scan_result.abuse_velocity = high">Advice : <b-badge variant="danger">high risk</b-badge> - previous reputation issues or low risk proxy/VPN.</p> -->
                <!-- <p v-else-if="ipr.fraud_score >= 88">Advice :<b-badge variant="danger">high risk</b-badge> - recent abusive behavior over the past 24-48 hours.</p> -->
                <p class="">
                  Advice : How frequently the IP address is engaging in abuse
                  across the IPQS threat network. Values can be "high",
                  "medium", "low", or "none". Can be used in combination with
                  the Fraud Score to identify bad behavior.
                </p>
              </div>
              <div>
                <p>
                  <b>Country Code</b>:
                  <span style="color: #7367f0">{{
                    items.current_scan_details.country_code
                  }}</span>
                </p>
                <p class="">
                  Advice : Two character country code of IP address or "N/A" if
                  unknown
                </p>
                <!-- <p class="" v-else-if="ipr.fraud_score >= 88">Advice :<b-badge variant="danger">high risk</b-badge> - recent abusive behavior over the past 24-48 hours.</p>
                    <p class="" v-else-if="ipr.fraud_score >= 90">Advice :<b-badge variant="danger">high risk</b-badge> - recent reputation issues like abuse associated with a name, email, phone number, payment method, etc.</p> -->
              </div>
              <div>
                <p>
                  <b>Region</b>:
                  <span style="color: #7367f0">{{
                    items.current_scan_details.region
                  }}</span>
                </p>
                <p class="">
                  Advice : Region (state) of IP address if available or "N/A" if
                  unknown.
                </p>
              </div>
              <div>
                <p>
                  <b>City</b>:
                  <span style="color: #7367f0">{{
                    items.current_scan_details.city
                  }}</span>
                </p>
                <p class="">
                  Advice : Region (state) of IP address if available or "N/A" if
                  unknown.
                </p>
              </div>
              <div>
                <p>
                  <b>Zip code</b>:
                  <span style="color: #7367f0">{{
                    items.current_scan_details.zip_code
                  }}</span>
                </p>
                <p class="">
                  Advice : Postal code of IP address if available or "N/A" if
                  unknown. IP addresses can relate to multiple postal codes in a
                  city, so we recommend performing analysis of similar postal
                  codes nearby.
                </p>
              </div>
              <div>
                <p>
                  <b>ISP</b>:
                  <span style="color: #7367f0">{{
                    items.current_scan_details.ISP
                  }}</span>
                </p>
                <p class="">Advice : ISP if one is known. Otherwise "N/A"</p>
              </div>
              <div>
                <p>
                  <b>ASN</b>:
                  <span style="color: #7367f0">{{
                    items.current_scan_details.ASN
                  }}</span>
                </p>
                <p class="">
                  Advice : Autonomous System Number if one is known. Null if
                  nonexistent.
                </p>
              </div>
              <div>
                <p>
                  <b>Organization</b>:
                  <span style="color: #7367f0">{{
                    items.current_scan_details.organization
                  }}</span>
                </p>
                <p class="">
                  Advice : Organization if one is known. Can be parent company
                  or sub company of the listed ISP. Otherwise "N/A".
                </p>
              </div>
              <div>
                <p>
                  <b>is_crawler</b>:
                  <span style="color: #7367f0">{{
                    items.current_scan_details.is_crawler
                  }}</span>
                </p>
                <p class="">
                  Advice : Is this IP associated with being a confirmed crawler
                  from a mainstream search engine such as Googlebot, Bingbot,
                  Yandex, etc. based on hostname or IP address verification.
                </p>
              </div>
              <div>
                <p>
                  <b>Timezone</b>:
                  <span style="color: #7367f0">{{
                    items.current_scan_details.timezone
                  }}</span>
                </p>
                <p class="">
                  Advice : Timezone of IP address if available or "N/A" if
                  unknown.
                </p>
              </div>
              <div>
                <p>
                  <b>Mobile</b>:
                  <span style="color: #7367f0">{{
                    items.current_scan_details.mobile
                  }}</span>
                </p>
                <p class="">
                  Advice : s this user agent a mobile browser? (will always be
                  false if the user agent is not passed in the API request)
                </p>
              </div>
              <div>
                <p>
                  <b>Host</b>:
                  <span style="color: #7367f0">{{
                    items.current_scan_details.host
                  }}</span>
                </p>
                <p class="">
                  Advice : Hostname of the IP address if one is available.
                </p>
              </div>
              <div>
                <p>
                  <b>Proxy</b>:
                  <span style="color: #7367f0">{{
                    items.current_scan_details.proxy
                  }}</span>
                </p>
                <p class="">
                  Advice : Is this IP address suspected to be a proxy? (SOCKS,
                  Elite, Anonymous, VPN, Tor, etc.)
                </p>
              </div>
              <div>
                <p>
                  <b>VPN</b>:
                  <span style="color: #7367f0">{{
                    items.current_scan_details.vpn
                  }}</span>
                </p>
                <p class="">
                  Advice : Is this IP suspected of being a VPN connection? This
                  can include data center ranges which can become active VPNs at
                  any time. The "proxy" status will always be true when this
                  value is true.
                </p>
              </div>
              <div>
                <p>
                  <b>TOR</b>:
                  <span style="color: #7367f0">{{
                    items.current_scan_details.timezone
                  }}</span>
                </p>
                <p class="">
                  Advice : Is this IP suspected of being a TOR connection? This
                  can include previously active TOR nodes and exits which can
                  become active TOR exits at any time. The "proxy" status will
                  always be true when this value is true.
                </p>
              </div>
              <div>
                <p>
                  <b>Active VPN</b>:
                  <span style="color: #7367f0">{{
                    items.current_scan_details.active_vpn
                  }}</span>
                </p>
                <p class="">
                  Advice : Identifies active VPN connections used by popular VPN
                  services and private VPN servers.
                </p>
              </div>
              <div>
                <p>
                  <b>Active TOR</b>:
                  <span style="color: #7367f0">{{
                    items.current_scan_details.active_tor
                  }}</span>
                </p>
                <p class="">
                  Advice : Identifies active TOR exits on the TOR network.
                </p>
              </div>
              <div>
                <p>
                  <b>Recent Abuse</b>:
                  <span style="color: #7367f0">{{
                    items.current_scan_details.recent_abuse
                  }}</span>
                </p>
                <p class="">
                  Advice : This value will indicate if there has been any
                  recently verified abuse across our network for this IP
                  address. Abuse could be a confirmed chargeback, compromised
                  device, fake app install, or similar malicious behavior within
                  the past few days.
                </p>
              </div>
              <div>
                <p>
                  <b>Bot Status</b>:
                  <span style="color: #7367f0">{{
                    items.current_scan_details.bot_status
                  }}</span>
                </p>
                <p class="">
                  Advice : Indicates if bots or non-human traffic has recently
                  used this IP address to engage in automated fraudulent
                  behavior. Provides stronger confidence that the IP address is
                  suspicious.
                </p>
              </div>
              <div>
                <p>
                  <b>Connection Type</b>:
                  <span style="color: #7367f0">{{
                    items.current_scan_details.connection_type
                  }}</span>
                </p>
                <p class="">
                  Advice : Classification of the IP address connection type as
                  "Residential", "Corporate", "Education", "Mobile", or "Data
                  Center".
                </p>
              </div>
              <div>
                <p>
                  <b>Abuse Velocity</b>:
                  <span style="color: #7367f0">{{
                    items.current_scan_details.abuse_velocity
                  }}</span>
                </p>
                <p class="">
                  Advice : How frequently the IP address is engaging in abuse
                  across the IPQS threat network. Values can be "high",
                  "medium", "low", or "none". Can be used in combination with
                  the Fraud Score to identify bad behavior.
                </p>
              </div>
              <div>
                <p>
                  <b>Latitude</b>:
                  <span style="color: #7367f0">{{
                    items.current_scan_details.latitude
                  }}</span>
                </p>
                <p class="">
                  Advice : Latitude of IP address if available or "N/A" if
                  unknown.
                </p>
              </div>
              <div>
                <p>
                  <b>Longitude</b>:
                  <span style="color: #7367f0">{{
                    items.current_scan_details.longitude
                  }}</span>
                </p>
                <p class="">
                  Advice : Longitude of IP address if available or "N/A" if
                  unknown.
                </p>
              </div>
              <div>
                <p>
                  <b>Request ID</b>:
                  <span style="color: #7367f0">{{
                    items.current_scan_details.request_id
                  }}</span>
                </p>
                <p class="">
                  Advice : A unique identifier for this request that can be used
                  to lookup the request details or send a postback conversion
                  notice.
                </p>
              </div>
            </b-card-code>
          </div>
        </div>
      </b-col>
    </div>
  </div>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import moment from "moment";
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BBadge,
  BTable,
  BAvatar,
} from "bootstrap-vue";
export default {
  components: {
    BCardCode,
    BCard,
    BRow,
    BButton,
    BCol,
    BBadge,
    BTable,
    BAvatar,
  },
  data() {
    return {
      //   items: [],
      //   vulnItems: [],
    };
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    asmSummary: {
      type: Boolean,
      required: true,
    },
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },
  // created: function () {
  //   this.getSummary();
  // },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
/* table, th, td {
  border:1px solid black;
} */
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ebe9f1;
}

th {
  text-align: left;
  padding: 8px;
  background-color: #f3f2f7;
}
[dir] .dark-layout th {
  background-color: #343d55;
}

[dir] .dark-layout td {
  border-bottom: 1px solid #3b4253;
}
</style>
