<template>
  <div>
    <div
      class="
        d-flex
        justify-content-between
        align-items-center
        content-sidebar-header
        px-2
        py-1
      "
    >
      <div>
        <h4 class="text-primary">{{ sslLabs.ipAddress }}</h4>
      </div>
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="closeSidebar()"
        />
      </div>
    </div>
    <div class="mt-2 mx-2">
      <!-- <div>{{ssl_items}}</div> -->
      <b-card align="center" class="bg-dark-blue h-100">
        <div class="row">
          <div class="col">
            <b-list-group class="list-group-circle text-left bg-transparent">
              <b-list-group-item class="bg-transparent">
                <b>IP Address:</b><span class=""> {{ sslLabs.ipAddress }}</span>
              </b-list-group-item>
              <b-list-group-item class="bg-transparent">
                <b>Grade:</b><span class=""> {{ sslLabs.grade }}</span>
              </b-list-group-item>
              <b-list-group-item class="bg-transparent">
                <b>Grade Trust Ignored:</b>
                <span class="">{{ sslLabs.gradeTrustIgnored }}</span>
              </b-list-group-item>
              <b-list-group-item class="bg-transparent">
                <b>Status Message:</b
                ><span class=""> {{ sslLabs.statusMessage }}</span>
              </b-list-group-item>
              <b-list-group-item class="bg-transparent">
                <b>Progress:</b><span class=""> {{ sslLabs.progress }}</span>
              </b-list-group-item>
              <b-list-group-item class="bg-transparent">
                <b>Duration:</b><span class=""> {{ sslLabs.duration }}</span>
              </b-list-group-item>
              <b-list-group-item class="bg-transparent">
                <b>Delegation:</b
                ><span class=""> {{ sslLabs.delegation }}</span>
              </b-list-group-item>
            </b-list-group>
          </div>
        </div>
      </b-card>
      <div class="row">
        <h4 class="text-primary mt-2 mx-2">Certificates</h4>
        <div class="col-12" v-for="(cert,index) in ssl_items" :key="index">
          <div class="text-primary mx-1 mb-1"><b>{{ index + 1 }} .</b> </div>
          <div class="row mx-1 mb-1" >
            <div class="col-6 border-primary" style="border-radius:5px">
              <div>
                <h6 class="mt-1">AltName</h6>
                <ul v-for="altName in cert.altNames" :key="altName">
                  <li>{{ altName }}</li>
                </ul>
              </div>
            </div>
            <div class="col-6 border-primary" style="border-radius:5px">
              <div>
                <h6 class="mt-1">CommonName</h6>
                <ul v-for="commonName in cert.commonNames" :key="commonName">
                  <li>{{ commonName }}</li>
                </ul>
              </div>
            </div>
          </div>
          <b-card
            class="border-primary mx-1"
            style="height: 300px; overflow-y: scroll; "
          >
            <p>{{ cert.raw }}</p>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BCard,
  BListGroup,
  BListGroupItem,
  BFormTextarea,
} from "bootstrap-vue";
export default {
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
  },
  props: {
    sslLabs: {
      type: Object,
      required: true,
    },
    ssl_items: {
      type: Array,
      required: true,
    },
    closeSidebar: {
      type: Function,
      required: true,
    },
  },
  watch: {
    sslLabs: function (old, newV) {},
  },
};
</script>
<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem;
}
.bg-dark-blue {
  /* background-color: #171153 !important; */
  background-color: #1a457b1f !important;
  /* background-color: #1e176c !important; */
}

[dir] .dark-layout .bg-dark-blue {
  background-color: #04002f !important;
  color: #fff;
}
[dir].dark-layout .list-group-item {
  padding: 0.75rem 1.25rem;
  background-color: #283046 !important;
  border: 1px solid rgb(68 101 129) !important;
  /* border-radius: 5px; */
}
</style>