<template>
  <div>
    <div class="d-flex justify-content-end" v-if="!asmSummary">
      <b-button @click="goBack()" size="sm" variant="primary">Back</b-button>
    </div>
    <div class="row align-items-center">
      <b-col class="col-4" v-if="!asmSummary">
        <h6>
          Scan Name: <span>{{ items.scan_type }}</span>
        </h6>
        <h6>
          Scan Start: <span>{{ items.created_at | formatdate }}</span>
        </h6>
        <h6>
          Scan End: <span>{{ items.last_run_at | formatdate }}</span>
        </h6>
        <h6>
          Asset: <span>{{ items.asset }}</span>
        </h6>
      </b-col>
      <b-col class="col-12" v-if="items.current_scan_details">
        <b-row class="mt-1" v-if="!asmSummary">
          <b-col class="col-12">
            <h4>Current Scan Details</h4>
          </b-col>
        </b-row>
        <b-row class="align-items-center">
          <b-col class="col-6">
            <b-row>
              <b-col class="">
                <b-card class="card bg-light-info border-info rounded">
                  <span>Down Hosts</span>
                  <h6 class="text-info">
                    {{ items.current_scan_details.downhosts }}
                  </h6>
                </b-card>
              </b-col>
              <b-col class="">
                <b-card class="card bg-light-info border-info rounded">
                  <span>Total Hosts</span>
                  <h6 class="text-info">
                    {{ items.current_scan_details.totalhosts }}
                  </h6>
                </b-card>
              </b-col>
              <b-col class="">
                <b-card class="card bg-light-info border-info rounded">
                  <span>Up Hosts</span>
                  <h6 class="text-info">
                    {{ items.current_scan_details.uphosts }}
                  </h6>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="col-6">
            <div class="row" v-if="items.current_scan_details">
              <div
                class="col-12"
                v-for="(key, value) in items.current_scan_details.ports"
                :key="key"
              >
                <b-card class="card w-100" style="border: 1px solid">
                  <h4 class="text-uppercase">{{ value }}</h4>
                  <table style="width: 100%">
                    <tr>
                      <th>Port</th>
                      <th>State</th>
                      <!-- <th class="text-center">Time</th> -->
                    </tr>
                    <tr v-for="(k, v) in key" :key="k">
                      <td>{{ v }}</td>
                      <td>
                        <b-badge variant="primary">{{ k.state }}</b-badge>
                      </td>
                      <!-- <td class="text-center">{{ k.endtime | formatdate}}</td> -->
                    </tr>
                  </table>
                </b-card>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <!-- <b-col > -->
      <b-row v-if="items.current_scan_details">
        <b-col>
          <b-table-simple caption-top responsive class="rounded-bottom mb-0">
            <b-thead head-variant="light">
              <b-tr>
                <!-- <b-th>#</b-th> -->
                <b-th>IP Address</b-th>
                <!-- <b-th>OS</b-th> -->
                <!-- <b-th>Asset Name</b-th> -->
                <b-th>Port</b-th>
                <b-th>Protocol</b-th>
                <b-th style="text-align: center">URL</b-th>
                <b-th style="text-align: center">Technology</b-th>
                <b-th style="text-align: center">Details</b-th>
                <b-th style="text-align: center">Screenshots</b-th>
                <!-- <b-th v-for="(field, index) in fields" :key="index" v-if="field.visible">{{ field.label }}</b-th> -->
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <!-- <b-td
                  :rowspan="
                    items.current_scan_details.nmap_result.ports.length + 1
                  "
                >
                  {{ index + 1 + perPage * (currentPage - 1) }}
                </b-td> -->
                <b-td
                  :rowspan="
                    items.current_scan_details.nmap_result.ports.length + 1
                  "
                  class="text-center"
                >
                  {{ items.current_scan_details.nmap_result.address }}
                </b-td>
                <!-- <b-td
                  :rowspan="
                    items.current_scan_details.nmap_result.ports.length + 1
                  "
                >
                  <router-link
                    :to="
                      '/assets/scan/' +
                      items.current_scan_details.nmap_result.asset_id
                    "
                    >{{ items.asset }}</router-link
                  ></b-td
                > -->
              </b-tr>
              <b-tr
                v-for="(nmapPort, npid) in items.current_scan_details
                  .nmap_result.ports"
                :key="npid"
              >
                <b-td> {{ nmapPort.portid }}/{{ nmapPort.protocol }} </b-td>
                <b-td>{{ nmapPort.service_name }}</b-td>
                <b-td>
                  <span
                    v-for="(whatwebPort, wp) in items.current_scan_details
                      .whatweb_result"
                    :key="wp"
                  >
                    <ul
                      v-if="
                        parseInt(whatwebPort.port) === parseInt(nmapPort.portid)
                      "
                    >
                      <li>
                        <strong>Target:</strong> {{ whatwebPort.target }}<br />
                        <strong>HTTP Status:</strong>
                        {{ whatwebPort.http_status }}<br />
                      </li>
                    </ul>
                  </span>
                </b-td>
                <b-td>
                  <span
                    v-for="(whatwebPort, wpid) in items.current_scan_details
                      .whatweb_result"
                    :key="wpid"
                  >
                    <ul
                      v-if="
                        parseInt(whatwebPort.port) === parseInt(nmapPort.portid)
                      "
                    >
                      <li><strong>HTML :</strong> {{ whatwebPort.html }}</li>
                      <li>
                        <strong>jquery :</strong> {{ whatwebPort.jquery }}
                      </li>
                      <li><strong>nginx :</strong> {{ whatwebPort.nginx }}</li>
                      <li>
                        <strong>wordpress :</strong> {{ whatwebPort.wordpress }}
                      </li>
                      <li>
                        <strong>x_hacker :</strong> {{ whatwebPort.x_hacker }}
                      </li>
                      <li>
                        <strong>Powered By :</strong>{{ whatwebPort.poweredby }}
                      </li>
                      <!-- <li>
                  <strong>x_ua_compatible :</strong>
                  {{ whatwebPort.x_ua_compatible }}
                </li> -->
                    </ul>
                  </span>
                </b-td>
                <b-td>
                  <span
                    v-for="(whatwebPort, wpid) in items.current_scan_details
                      .whatweb_result"
                    :key="wpid"
                  >
                    <ul
                      v-if="
                        parseInt(whatwebPort.port) === parseInt(nmapPort.portid)
                      "
                    >
                      <li>
                        <strong>HTTP Server OS:</strong>
                        {{ whatwebPort.http_server_os }}
                      </li>
                      <li>
                        <strong>HTTP Server :</strong>
                        {{ whatwebPort.http_server }}
                      </li>
                      <li><strong>Title :</strong> {{ whatwebPort.title }}</li>
                      <li v-if="whatwebPort.redirect_location != null">
                        <strong>Redirect Location :</strong>
                        {{ whatwebPort.redirect_location }}
                      </li>
                      <li>
                        <strong>Uncommon Header :</strong>
                        {{ whatwebPort.uncommon_headers }}
                      </li>
                    </ul>
                  </span>
                </b-td>
                <b-td>
                  <!-- <span v-for="(whatwebPort, wp) in result.whatweb_result" :key="wp"> -->
                  <div v-for="(assetImage, index) in imageUrls" :key="index">
                    <span v-for="imageport in assetImage" :key="imageport">
                      <span
                        v-if="
                          parseInt(imageport.port) ===
                            parseInt(nmapPort.portid) &&
                          parseInt(imageport.asset_id) ===
                            items.current_scan_details.nmap_result.asset_id
                        "
                      >
                        <a
                          :href="items.current_scan_details.asset_name"
                          target="_blank"
                        >
                          <img
                            :key="index"
                            :src="imageport.url"
                            height="150px"
                            width="200px"
                            alt="Image"
                          />
                        </a>
                      </span>
                    </span>
                  </div>
                  <!-- </span> -->
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <b-row v-else class="justify-content-center w-100">
        <b-col class="col-12" >
          <div
            class="w-100 d-flex flex-column align-items-center justify-content-center py-5"
          >
            <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
            <h3 class="font-weight-bolder">No Data Found</h3>
          </div>
        </b-col>
      </b-row>
      <!-- </b-col> -->
    </div>
  </div>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import moment from "moment";
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BBadge,
  BTable,
  BAvatar,
  BThead,
  BTableColumn,
  BTableSimple,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
} from "bootstrap-vue";
import BSTableSimple from "@/components/bs_table/TableSimple";
export default {
  components: {
    BCardCode,
    BCard,
    BRow,
    BButton,
    BCol,
    BBadge,
    BTable,
    BAvatar,
    BSTableSimple,
    BTableColumn,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
  },
  data() {
    return {
      fields: [
        { label: "#", visible: true },
        { label: "IP Address", visible: true },
        { label: "OS", visible: true },
        { label: "Port", visible: true },
        { label: "Protocol", visible: true },
        { label: "URL", visible: false },
        { label: "Technology", visible: true },
      ],
      xfields: [
        { key: "index", label: "#", visible: true },
        // { key: "nmap_result", label: "Nmaper", visible: true },
        // { key: "whatweb_result", label: "WhatWeb", visible: true },
        { key: "address", label: "IP Address", visible: true },
        { key: "asset_name", label: "Hostname", visible: true },
        { key: "os", label: "OS", visible: false },
        // { key: "country", label: "country", visible: true },
        // { key: "addrtype", label: "Type", visible: true },

        // { key: "ports", label: "Port", visible: false },
        { key: "protocol", label: "Protocol", visible: true },
        { key: "technology", label: "Technology", visible: true },
        { key: "url", label: "URL", visible: false },
      ],
      perPage: 10,
      currentPage: 1,
      items: [],
      imageUrls: [],
    };
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    ports: {
      type: Array,
      required: true,
    },
    asmSummary: {
      type: Boolean,
      required: true,
    },
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },
  created: function () {
    this.getImage();
  },
  methods: {
    getImage() {
      const assetImageUrls = []; // Array to store image URLs for the current asset
      this.items.current_scan_details.whatweb_result.forEach(
        (whatwebPort, wp) => {
          console.log(whatwebPort.image_data, "dfskjfkld");
          if (whatwebPort.image_data) {
            this.getImageUrl(whatwebPort.image_data).then((imageUrl) => {
              assetImageUrls.push({
                port: whatwebPort.port,
                url: imageUrl,
                asset_id: this.items.current_scan_details.nmap_result.asset_id,
                link: this.items.current_scan_details.asset_name,
              });
            });
          }
        }
      );
      this.imageUrls.push(assetImageUrls); // Add the array for the current asset
    },
    getImageUrl(imageData) {
      return new Promise((resolve, reject) => {
        const imageInfo = JSON.parse(imageData);
        const data = {
          file_obj: imageInfo,
        };
        const options = {
          method: "POST",
          data: data,
          url:
            process.env.VUE_APP_BASEURL +
            "asset/attack-surface/download-screenshot/",
          responseType: "blob",
        };

        this.$http(options)
          .then((res) => {
            console.log("Image Data:", res.data);
            const url = window.URL.createObjectURL(new Blob([res.data]));
            resolve(url);
          })
          .catch((error) => {
            console.error("Error fetching image:", error);
            reject(error);
          });
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
/* table, th, td {
  border:1px solid black;
} */
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ebe9f1;
}

th {
  text-align: left;
  padding: 8px;
  background-color: #f3f2f7;
}
[dir] .dark-layout th {
  background-color: #343d55;
}

[dir] .dark-layout td {
  border-bottom: 1px solid #3b4253;
}
</style>
