<template>
  <b-col cols="12">
    <div>
      <div class="d-flex justify-content-end" v-if="!asmSummary">
        <b-button @click="goBack()" size="sm" variant="primary">Back</b-button>
      </div>
      <div class="row align-items-center" v-if="!asmSummary">
        <b-col class="col-4">
          <h6>
            Scan Name: <span>{{ items.scan_type }}</span>
          </h6>
          <h6>
            Scan Start: <span>{{ items.created_at | formatdate }}</span>
          </h6>
          <h6>
            Scan End: <span>{{ items.last_run_at | formatdate }}</span>
          </h6>
          <h6>
            Asset: <span>{{ items.asset }}</span>
          </h6>
        </b-col>
      </div>
    </div>
    <b-row class="mt-1">
      <b-col>
        <h4 class="text-primary" v-if="!asmSummary">Current Scan Details</h4>
        <!-- v-if="Object.keys(items.current_scan_details).length > 0" -->
        <b-card-code
          class="border-secondary"
          no-body
          v-if="items.current_scan_details"
        >
          <div class="row m-2">
            <div class="col-12">
              <p><b-badge variant="success">SPF</b-badge></p>
              <b-card-code class="">
                <p>Value : {{ items.current_scan_details.spf }}</p>
                <p>Advice : {{ adviceVariable.spf }}</p>
              </b-card-code>
            </div>
            <div class="col-12">
              <p><b-badge variant="success">MX</b-badge></p>
              <b-card-code
                class=""
                v-for="mxvalue in items.current_scan_details.mx"
                :key="mxvalue"
              >
                <p>
                  Value :
                  <span v-if="mxvalue">{{ mxvalue }} </span>
                  <span v-else>None</span>
                </p>
                <p>
                  Advice :
                  <span v-for="mx in adviceVariable.mx" :key="mx">
                    <span v-if="mx">{{ mx }} </span>
                    <span v-else>None</span>
                  </span>
                </p>
              </b-card-code>
            </div>
            <div class="col-12">
              <p><b-badge variant="success">DKIM</b-badge></p>
              <b-card-code class="">
                <p>
                  Value :
                  <span v-if="items.current_scan_details.dkim"
                    >{{ items.current_scan_details.dkim }}
                  </span>
                  <span v-else>None</span>
                </p>
                <span v-for="dkim in adviceVariable.dkim" :key="dkim">
                  <p>Advice : {{ dkim }}</p>
                </span>
              </b-card-code>
            </div>
            <div class="col-12">
              <p><b-badge variant="success">DMARC</b-badge></p>
              <b-card-code class="">
                <p>
                  Value :
                  <span v-if="items.current_scan_details.dmarc"
                    >{{ items.current_scan_details.dmarc }}
                  </span>
                  <span v-else>None</span>
                </p>
                <span v-for="dmarc in adviceVariable.dmarc" :key="dmarc">
                  <p>Advice : {{ dmarc }}</p>
                </span>
              </b-card-code>
            </div>
          </div>
        </b-card-code>
        <div v-else class="">
          <div
            class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
          >
            <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
            <h3 class="font-weight-bolder">No Data Found</h3>
          </div>
        </div>
      </b-col>
      <b-col v-if="items.previous_scan_details">
        <h4 class="text-primary">Privious Scan Details</h4>
        <b-card-code
          class="border-secondary"
          no-body
          v-if="Object.keys(items.previous_scan_details).length > 0"
        >
          <div class="row m-2">
            <div class="col-12">
              <p><b-badge variant="success">SPF</b-badge></p>
              <b-card-code class="">
                <p>Value : {{ items.previous_scan_details.spf }}</p>
                <p>Advice : {{ adviceVariable.spf }}</p>
              </b-card-code>
            </div>
            <div class="col-12">
              <p><b-badge variant="success">MX</b-badge></p>
              <b-card-code
                class=""
                v-for="mxvalue in items.previous_scan_details.mx"
                :key="mxvalue"
              >
                <p>
                  Value :
                  <span v-if="mxvalue">{{ mxvalue }} </span>
                  <span v-else>None</span>
                </p>
                <p>
                  Advice :
                  <span v-for="mx in previousAdiveVariable.mx" :key="mx">
                    <span v-if="mx">{{ mx }} </span>
                    <span v-else>None</span>
                  </span>
                </p>
              </b-card-code>
            </div>
            <div class="col-12">
              <p><b-badge variant="success">DKIM</b-badge></p>
              <b-card-code class="">
                <p>
                  Value :
                  <span v-if="items.previous_scan_details.dkim"
                    >{{ items.current_scan_details.dkim }}
                  </span>
                  <span v-else>None</span>
                </p>
                <span v-for="dkim in previousAdiveVariable.dkim" :key="dkim">
                  <p>Advice : {{ dkim }}</p>
                </span>
              </b-card-code>
            </div>
            <div class="col-12">
              <p><b-badge variant="success">DMARC</b-badge></p>
              <b-card-code class="">
                <p>
                  Value :
                  <span v-if="items.previous_scan_details.dmarc"
                    >{{ items.previous_scan_details.dmarc }}
                  </span>
                  <span v-else>None</span>
                </p>
                <span v-for="dmarc in previousAdiveVariable.dmarc" :key="dmarc">
                  <p>Advice : {{ dmarc }}</p>
                </span>
              </b-card-code>
            </div>
          </div>
        </b-card-code>
        <div v-else class="border-secondary rounded">
          <div
            class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
          >
            <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
            <h3 class="font-weight-bolder">No Data Found</h3>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-col>
</template>



<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BModal,
  BPagination,
  BPaginationNav,
  BButton,
  BRow,
  BCol,
  BBadge,
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BSidebar,
  VBToggle,
  BTab,
  BTabs,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
// import esecurtityDetail from "../esecurtity_details.vue";
import moment from "moment";

export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardHeader,
    BCardBody,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    ValidationProvider,
    ValidationObserver,
    BSidebar,
    BTab,
    BTabs,
    // VBToggle,
    // esecurtityDetail,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      pagination_pos: "center",
      organization_id: "",
      asset_id: "",

      // Scanner attachment modal
      task_title: "",
      polling: null,

      esecurtity: {},
      esecurtity_items: [],
      esecurtity_currentPage: 1,
      esecurtity_perPage: 10,
      esecurtity_rows: 0,
      esecurtity_fields: [
        { key: "index", label: "#" },
        // { key: "vuln_id", label: "Vuln Id" },
        // { key: "name", label: "Name" },
        // { key: "severity", label: "Severity" },
        // { key: "source", label: "Source" },
        // { key: "path", label: "Path", tdClass: "path-cl" },
        // { key: "status", label: "Status" },
        // { key: "asset_id", label: "Asset ID" },
        { key: "spf", label: "SPF" },
        { key: "mx", label: "MX" },
        { key: "dkim", label: "DKIM" },
        { key: "dmarc", label: "DMARC" },
      ],
      adviceVariable: null,
      currentAdiveVariable: null,
      previousAdiveVariable: null,
    };
  },
  props: {
    items: {
      type: Object,
      required: true,
    },
    asmSummary: {
      type: Boolean,
      required: true,
    },
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },
  mounted() {
    // Check if items.advice exists and is an object
    if (
      this.items &&
      this.items.current_scan_details.advice &&
      typeof this.items.current_scan_details.advice === "object"
    ) {
      // Assign the advice object to the adviceVariable
      this.adviceVariable = this.items.current_scan_details.advice;
    }
    if (
      this.items &&
      this.items.previous_scan_details.advice &&
      typeof this.items.previous_scan_details.advice === "object"
    ) {
      this.previousAdiveVariable = this.items.previous_scan_details.advice;
    }
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
  // reference: https://renatello.com/vue-js-polling-using-setinterval/
};
</script>

<style lang="scss" scoped>
</style>
