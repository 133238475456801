<template>
  <div>
    <div class="d-flex justify-content-end" v-if="!asmSummary">
      <b-button @click="goBack()" size="sm" variant="primary">Back</b-button>
    </div>
    <div class="row align-items-center" v-if="!asmSummary">
      <b-col class="col-4">
        <h6>
          Scan Name: <span>{{ items.scan_type }}</span>
        </h6>
        <h6>
          Scan Start: <span>{{ items.created_at | formatdate }}</span>
        </h6>
        <h6>
          Scan End: <span>{{ items.last_run_at | formatdate }}</span>
        </h6>
        <h6>
          Asset: <span>{{ items.asset }}</span>
        </h6>
      </b-col>
    </div>
    <b-row>
      <b-col cols="12" class="mt-1">
        <!-- {{items.current_scan_details.certs}} -->
        <b-card-code class="border-primary" title="SSL Score" no-body>
          <b-table
            responsive
            id="SSLTable"
            :fields="sslLabs_fields"
            :items="items.current_scan_details.endpoints"
            :per-page="sslLabs_perPage"
            :current-page="sslLabs_currentPage"
            hover
          >
            <template #cell(index)="data">
              {{ data.index + 1 + sslLabs_perPage * (sslLabs_currentPage - 1) }}
            </template>
            <template #cell(name)="data">
              <a class="text-primary h5" @click="showSidebar(data)">{{
                data.item.ipAddress
              }}</a>
              <br />
              <span>{{ data.item.statusMessage }}</span>
            </template>
            <template #cell(testtime)="data">
              <p class="mb-0">
                {{
                  data.item.details.httpTransactions[0].responseHeaders[1].value
                }}
                <br /><span
                  ><b>Duration:</b> {{ data.item.duration | formattime }}</span
                >
              </p>
            </template>
            <!-- <template #cell(grade)="data">
              <h4 v-if="data.item.grade === 'A+' || 'A'" class="text-success">
                <b>{{ data.item.grade }}</b>
              </h4>
              <h4
                v-else-if="data.item.grade === 'B' || 'C' || 'D' || 'E' || 'F'"
                class="text-warning"
              >
                <b>{{ data.item.grade }}</b>
              </h4>
              <h4
                v-else-if="data.item.grade === 'A-' || 'T' || 'M'"
                class="text-danger"
              >
                <b>{{ data.item.grade }}</b>
              </h4>
              <h4 v-else>{{ data.item.grade }}</h4>
            </template> -->
            <template #cell(grade)="data">
              <h4 v-if="data.item.grade === 'A+' || data.item.grade === 'A'">
                <b class="text-success">{{ data.item.grade }}</b>
              </h4>
              <h4 v-else-if="data.item.grade === 'B'">
                <b style="color: #ffff00">{{ data.item.grade }}</b>
              </h4>
              <h4 v-else-if="data.item.grade === 'C'">
                <b class="text-warning">{{ data.item.grade }}</b>
              </h4>
              <h4 v-else-if="data.item.grade === 'F'">
                <b class="text-danger">{{ data.item.grade }}</b>
              </h4>
              <h4
                v-else-if="
                  data.item.grade === 'A-' ||
                  data.item.grade === 'T' ||
                  data.item.grade === 'M'
                "
              >
                <b style="color: #a020f0">{{ data.item.grade }}</b>
              </h4>
              <h4 v-else>
                {{ data.item.grade }}
              </h4>
            </template>
          </b-table>

          <b-sidebar
            id="sidebar-task-handler"
            sidebar-class="sidebar-lg"
            bg-variant="white"
            shadow
            backdrop
            no-header
            right
          >
            <!-- <h1>{{sslLabs_items}}</h1> -->
            <sslLabsDetail
              :sslLabs="sslLabs"
              :ssl_items="items.current_scan_details.certs"
              :closeSidebar="closeSidebar"
            />

            <!-- <esecurtityDetail
            :esecurtity="esecurtity"
            :callBack="load"
            :closeSidebar="closeSidebar"
            :asset_id="asset_id"
            :organization="parseInt(organization_id)"
          /> -->
          </b-sidebar>
        </b-card-code>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import moment from "moment";
import {
  BTable,
  BModal,
  BPagination,
  BPaginationNav,
  BButton,
  BRow,
  BCol,
  BBadge,
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BSidebar,
  VBToggle,
} from "bootstrap-vue";
import SslLabsDetail from "../../Asset/components/SslLab_Details.vue";
export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardHeader,
    BCardBody,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    // ValidationProvider,
    // ValidationObserver,
    BSidebar,
    // VBToggle,
    SslLabsDetail,
  },
  data() {
    return {
      pagination_pos: "center",

      asset_id: "",

      // Scanner attachment modal
      task_title: "",
      polling: null,

      sslLabs: {},
      sslLabs_items: [],
      sslLabs_currentPage: 1,
      sslLabs_perPage: 10,
      sslLabs_rows: 0,
      sslLabs_fields: [
        // { key: "ipAddress", label: "#" },
        { key: "index", label: "#" },
        { key: "name", label: "Server" },
        { key: "testtime", label: "Test Time" },
        { key: "grade", label: "Grade" },
      ],
    };
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    asmSummary: {
      type: Boolean,
      required: true,
    },
  },
  filters: {
    formattime: function (ms) {
      let seconds = (ms / 1000).toFixed(1);
      let minutes = (ms / (1000 * 60)).toFixed(1);
      let hours = (ms / (1000 * 60 * 60)).toFixed(1);
      let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
      if (seconds < 60) return seconds + " Sec";
      else if (minutes < 60) return minutes + " Min";
      else if (hours < 24) return hours + " Hrs";
      else return days + " Days";
    },

    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },
  // created: function () {
  //   this.getSummary();
  // },
  methods: {
    showSidebar: function (data) {
      this.sslLabs = data.item;
      console.log("sidebar ssl", data);
      // this.$refs['taskSideBar'].show();
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
      // this.isTaskHandlerSidebarActive = true
    },
    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
/* table, th, td {
  border:1px solid black;
} */
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ebe9f1;
}

th {
  text-align: left;
  padding: 8px;
  background-color: #f3f2f7;
}
[dir] .dark-layout th {
  background-color: #343d55;
}

[dir] .dark-layout td {
  border-bottom: 1px solid #3b4253;
}
</style>
