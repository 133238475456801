<template>
  <div>
    <div class="d-flex justify-content-end">
      <b-button @click="goBack()" size="sm" variant="primary">Back</b-button>
    </div>
    <div class="row align-items-center">
      <b-col class="col-4">
        <h6>
          Scan Name: <span>{{ items.scan_type }}</span>
        </h6>
        <h6>
          Scan Start: <span>{{ items.created_at | formatdate }}</span>
        </h6>
        <h6>
          Scan End: <span>{{ items.last_run_at | formatdate }}</span>
        </h6>
        <h6>
          Asset: <span>{{ items.asset }}</span>
        </h6>
      </b-col>
      <b-col class="col-12" v-if="items.current_scan_details">
        <b-row class="mt-1">
          <b-col class="col-12">
            <h4>Current Scan Details</h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="4">
            <b-card class="card bg-light-info border-info rounded">
              <span>Down Hosts</span>
              <h6 class="text-info">
                {{ items.current_scan_details.downhosts }}
              </h6>
            </b-card>
          </b-col>
          <b-col class="4">
            <b-card class="card bg-light-info border-info rounded">
              <span>Total Hosts</span>
              <h6 class="text-info">
                {{ items.current_scan_details.totalhosts }}
              </h6>
            </b-card>
          </b-col>
          <b-col class="4">
            <b-card class="card bg-light-info border-info rounded">
              <span>Up Hosts</span>
              <h6 class="text-info">
                {{ items.current_scan_details.uphosts }}
              </h6>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="col-12">
        <div class="row" v-if="items.current_scan_details">
          <div
            class="col-4"
            v-for="(key, value) in items.current_scan_details.ports"
            :key="key"
          >
            <b-card class="card w-100" style="border: 1px solid">
              <h4 class="text-uppercase">{{ value }}</h4>
              <table style="width: 100%">
                <tr>
                  <th>Port</th>
                  <th>State</th>
                  <!-- <th class="text-center">Time</th> -->
                </tr>
                <tr v-for="(k, v) in key" :key="k">
                  <td>{{ v }}</td>
                  <td>
                    <b-badge variant="primary">{{ k.state }}</b-badge>
                  </td>
                  <!-- <td class="text-center">{{ k.endtime | formatdate}}</td> -->
                </tr>
              </table>
            </b-card>
          </div>
        </div>
      </b-col>
    </div>
  </div>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import moment from "moment";
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BBadge,
  BTable,
  BAvatar,
} from "bootstrap-vue";
export default {
  components: {
    BCardCode,
    BCard,
    BRow,
    BButton,
    BCol,
    BBadge,
    BTable,
    BAvatar,
  },
  data() {
    return {
      //   items: [],
      //   vulnItems: [],
    };
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    ports: {
      type: Array,
      required: true,
    },
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },
  // created: function () {
  //   this.getSummary();
  // },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
/* table, th, td {
  border:1px solid black;
} */
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ebe9f1;
}

th {
  text-align: left;
  padding: 8px;
  background-color: #f3f2f7;
}
[dir] .dark-layout th {
  background-color: #343d55;
}

[dir] .dark-layout td {
  border-bottom: 1px solid #3b4253;
}
</style>
