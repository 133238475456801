<template>
  <div>
    <div class="d-flex justify-content-end">
      <b-button @click="goBack()" size="sm" variant="primary">Back</b-button>
    </div>
    <div class="row align-items-center mt-1">
      <b-col class="col-4">
        <!-- <h3>{{items}}</h3> -->
        <h6>
          Scan Name: <span>{{ items.scan_type }}</span>
        </h6>
        <h6>
          Scan Start: <span>{{ items.created_at | formatdate }}</span>
        </h6>
        <h6>
          Scan End: <span>{{ items.last_run_at | formatdate }}</span>
        </h6>
        <h6 v-if="items.asset">
          Asset: <span>{{ items.asset }}</span>
        </h6>
        <h6 v-if="items.asset_group">
          Asset Group: <span>{{ items.asset_group }}</span>
        </h6>
        <!-- <h6>
          Newly Founded Vuln Count:
          <span>{{ items.newly_founded_vuln_count }}</span>
        </h6>#a020f0
        <h6>
          Closed Vuln Count: <span>{{ items.closed_vuln_count }}</span>
        </h6> -->
        <h6>
          Threat:
          <b-badge
            v-if="items.threat_level == 'Critical'"
            :style="{ backgroundColor: '#a020f0', color: '#fff' }"
            >{{ items.threat_level }}</b-badge
          >
          <b-badge v-if="items.threat_level == 'High'" variant="danger">{{
            items.threat_level
          }}</b-badge>
          <b-badge v-if="items.threat_level == 'Medium'" variant="warning">{{
            items.threat_level
          }}</b-badge>
          <b-badge v-if="items.threat_level == 'Low'" variant="success">{{
            items.threat_level
          }}</b-badge>
          <b-badge v-if="items.threat_level == 'Info'" variant="info">{{
            items.threat_level
          }}</b-badge>
           <b-badge v-if="items.threat_level == 'Safe'" variant="success">{{
            items.threat_level
          }}</b-badge>
        </h6>
      </b-col>
      <b-col class="4">
        <b-card class="card bg-light-info border-info rounded">
          <span> Newly Founded Vuln Count</span>
          <h6 class="text-info">
            {{ items.previous_scan_details.newly_founded_vuln_count }}
          </h6>
        </b-card>
      </b-col>
      <b-col class="4">
        <b-card class="card bg-light-info border-info rounded">
          <span>Closed Vuln Count</span>
          <h6 class="text-info">
            {{ items.previous_scan_details.closed_vuln_count }}
          </h6>
        </b-card>
      </b-col>
      <b-col class="col-12">
        <b-row class="mt-1">
          <b-col class="col-12">
            <h4>Current Scan Details</h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="2">
            <b-card class="card bg-purple  border-purple rounded">
              <span class="text-purple">New Critical Vulns</span>
              <h6 class="text-purple">
                {{ items.current_scan_details.new_critical_vulns }}
              </h6>
            </b-card>
          </b-col>
          <b-col class="2">
            <b-card class="card bg-light-danger border-danger rounded">
              <span>New High Vulns</span>
              <h6 class="text-danger">
                {{ items.current_scan_details.new_high_vulns }}
              </h6>
            </b-card>
          </b-col>
          <b-col class="2">
            <b-card class="card bg-light-warning border-warning rounded">
              <span>New Medium Vulns</span>
              <h6 class="text-warning">
                {{ items.current_scan_details.new_medium_vulns }}
              </h6>
            </b-card>
          </b-col>
          <b-col class="2">
            <b-card class="card bg-light-success border-success rounded">
              <span>New Low Vulns</span>
              <h6 class="text-success">
                {{ items.current_scan_details.new_low_vulns }}
              </h6>
            </b-card>
          </b-col>
          <b-col class="2">
            <b-card class="card bg-light-info border-info rounded">
              <span>New Info Vulns</span>
              <h6 class="text-info">
                {{ items.current_scan_details.new_info_vulns }}
              </h6>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="col-12">
        <b-row>
          <b-col class="col-12">
            <h4>Previous Scan Details</h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="2">
            <b-card class="card bg-purple border-purple rounded">
              <span class="text-purple">Previous Critical Vulns</span>
              <h6 class="text-purple">
                {{ items.previous_scan_details.previous_critical_vulns }}
              </h6>
            </b-card>
          </b-col>
          <b-col class="2">
            <b-card class="card bg-light-danger border-danger rounded">
              <span>Previous High Vulns</span>
              <h6 class="text-danger">
                {{ items.previous_scan_details.previous_high_vulns }}
              </h6>
            </b-card>
          </b-col>
          <b-col class="2">
            <b-card class="card bg-light-warning border-warning rounded">
              <span>Previous Medium Vulns</span>
              <h6 class="text-warning">
                {{ items.previous_scan_details.previous_medium_vulns }}
              </h6>
            </b-card>
          </b-col>
          <b-col class="2">
            <b-card class="card bg-light-success border-success rounded">
              <span>Previous Low Vulns</span>
              <h6 class="text-success">
                {{ items.previous_scan_details.previous_low_vulns }}
              </h6>
            </b-card>
          </b-col>
          <b-col class="2">
            <b-card class="card bg-light-info border-info rounded">
              <span>Previous Info Vulns</span>
              <h6 class="text-info">
                {{ items.previous_scan_details.previous_info_vulns }}
              </h6>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </div>
    <div class="row">
      <b-col class="col-12">
        <b-table
          responsive
          id="vulnTable"
          :fields="fields"
          :items="vulnItems"
          v-if="!loading"
          :per-page="perPage"
          :current-page="currentPage"
          hover
        >
          <template #cell(index)="data">
            {{ data.index + 1 + perPage * (currentPage - 1) }}
          </template>
          <template #cell(is_verified)="data">
            <b-avatar v-if="data.item.is_verified" variant="success">
              <feather-icon icon="ThumbsUpIcon" />
            </b-avatar>
            <b-avatar v-else variant="danger">
              <feather-icon icon="ThumbsDownIcon" />
            </b-avatar>
          </template>
          <template v-slot:cell(severity)="data">
            <b-badge
              style="background-color: #991d28"
              v-if="data.item.severity == 'critical'"
              >Critical</b-badge
            >
            <b-badge variant="danger" v-else-if="data.item.severity == 'high'"
              >High</b-badge
            >
            <b-badge
              variant="warning"
              v-else-if="data.item.severity == 'medium'"
              >Medium</b-badge
            >
            <b-badge variant="primary" v-else-if="data.item.severity == 'low'"
              >Low</b-badge
            >
            <b-badge variant="success" v-else-if="data.item.severity == 'info'"
              >Info</b-badge
            >
            <b-badge variant="secondary" v-else>Unknown</b-badge>
          </template>
          <template v-slot:cell(status)="data">
            <b-badge variant="danger" v-if="data.item.status == 0"
              >Open</b-badge
            >
            <b-badge variant="success" v-if="data.item.status == 1"
              >Closed</b-badge
            >
            <b-badge variant="warning" v-if="data.item.status == 2"
              >False Positive</b-badge
            >
            <b-badge variant="success" v-if="data.item.status == 3"
              >Accepted</b-badge
            >
            <b-badge variant="warning" v-if="data.item.status == 4"
              >Assigned</b-badge
            >
            <b-badge variant="warning" v-if="data.item.status == 5"
              >Mitigated</b-badge
            >
          </template>
          <template v-slot:cell(is_assigned)="data">
            <b-badge variant="success" v-if="data.item.is_assigned == true"
              >Yes</b-badge
            >
            <b-badge variant="danger" v-else>No</b-badge>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </div>
  </div>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BBadge,
  BTable,
  BAvatar,
  BPaginationNav,
  BPagination,
} from "bootstrap-vue";
import moment from "moment";
export default {
  components: {
    BCardCode,
    BCard,
    BRow,
    BButton,
    BCol,
    BBadge,
    BTable,
    BAvatar,
    BPaginationNav,
    BPagination,
  },
  data() {
    return {
      items: [],
      vulnItems: [],
      perPage: 100,
      totalRows: 0,
      currentPage: 1,
      fields: [
        { key: "index", label: "#" },
        // { key: "", label: "select" },
        { key: "name", label: "Name", sortable: true },
        { key: "port", label: "Port" },
        { key: "created_at", label: "Added At" },
        { key: "is_verified", label: "Verified" },
        { key: "severity", label: "Severity" },
        { key: "status", label: "Status" },
        { key: "is_assigned", label: "Assigned" },
      ],
    };
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    vulnItems: {
      type: Array,
      required: true,
    },
    totalRows: {
      type: Number,
      required: true,
    },
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },
  // created: function () {
  //   this.getSummary();
  // },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.bg-purple {
  background: rgb(160 32 240 / 22%) !important;
}
.text-purple {
  color: #a020f0 !important;
}
.border-purple {
  border: 1px solid #a020f0 !important;
}
</style>
